import Chart from "chart.js/auto";
import { GraphWrapper } from "./Analytics.Style";
import { Line } from "react-chartjs-2";

const shadowPlugin = {
  id: "shadowPlugin",
  afterDraw: function (chart) {
    const ctx = chart.ctx;

    chart.data.datasets.forEach(function (dataset, datasetIndex) {
      const meta = chart.getDatasetMeta(datasetIndex);
      if (!meta.hidden) {
        meta.data.forEach(function (element, index) {
          if (element.skip) {
            return;
          }
          const { x, y, radius } = element;
          // Draw shadow effect on each point
          ctx.save();
          ctx.shadowColor = "rgba(0, 0, 0, 0.9)";
          ctx.shadowBlur = 8;
          ctx.shadowOffsetX = 2;
          ctx.shadowOffsetY = 2;

          ctx.beginPath();
          ctx.arc(x, y, radius + 3, 0, Math.PI * 2);
          ctx.fillStyle = "rgba(0, 0, 0, 0.9)";
          ctx.fill();
          ctx.closePath();

          ctx.restore();
        });
      }
    });
  },
};

function MainGraph(props) {
  return (
    <GraphWrapper height="320px">
      <Line
        key={props.id}
        data={props.chartData}
        options={{
          layout: {
            autoPadding: false,
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          elements: {
            point: {
              pointStyle: true,
              borderWidth: 1.5,
              pointRadius: 5,
              borderColor: "#FFFFFF",
              pointBackgroundColor: "#FFAB2D",
            },
            line: {
              borderWidth: 3,
              borderColor: "#FFAB2D",
            },
          },
          scales: {
            x: {
              border: {
                width: 0,
                display: false,
              },
              grid: {
                display: false,
              },
            },
            y: {
              border: {
                width: 0,
                display: false,
              },
            },
          },
        }}
        plugins={[shadowPlugin]}
      />
    </GraphWrapper>
  );
}

export default MainGraph;
