import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Message from "../assets/images/Message.svg";
import Lock from "../assets/images/key.svg";
import Logo from "../assets/images/main-logo.png";

import Input from "../components/Input/Input";
import Button from "../components/Input/Button";

import { AuthAPIs } from "../apis";
import { Container } from "../components/Container.Style";

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [progress, setProgress] = useState(false);

  const login = () => {
    if (progress) return;
    setProgress(true);
    console.log(AuthAPIs.login);
    fetch(AuthAPIs.login, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status !== "authorized") throw res;
        window.localStorage.setItem("token", res.token);
        const redirectUrl = new URLSearchParams(window.location.search).get(
          "redirect"
        );
        navigate(redirectUrl ? redirectUrl : "/dashboard");
      })
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => setProgress(false));
  };

  return (
    <Container display={"flex"}>
      <div style={{ width: "100%", maxWidth: "350px", margin: "auto" }}>
        <img
          src={Logo}
          style={{ display: "block", width: "100%", margin: "auto" }}
          alt="main-logo"
        />
        <br />
        <br />
        <div style={{ width: "100%" }}>
          <Input
            src={Message}
            label="Email"
            value={email}
            type="email"
            name="email"
            onChange={(e) => setEmail(e.target.value || "")}
          />
          <Input
            src={Lock}
            label="Password"
            value={password}
            type="password"
            name="password"
            onChange={(e) => setPassword(e.target.value || "")}
          />
          <br />
          <Button
            onClick={login}
            btnType={`${progress ? "disabled" : ""} loginBtn`}
            name="Login"
          />
        </div>
      </div>
    </Container>
  );
}

export default Login;
