import { createBrowserRouter, RouterProvider } from "react-router-dom";

import AuthProvider from "./Auth";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Conferences from "./pages/Conferences";
import Conference from "./pages/Conference";
import Analytics from "./pages/Analytics";
import Settings from "./pages/Settings";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/conferences",
    element: <Conferences />,
  },
  {
    path: "/conferences/:id",
    element: <Conference />,
  },
  {
    path: "/analytics",
    element: <Dashboard />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
