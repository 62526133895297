import { useState, useEffect } from "react";

import { ContentHeader, PlaceHolder } from "../Container.Style";
import { Grid } from "./Conference.Style";
import { ModalForm } from "../Modal/Modal.Style";

import Modal from "../Modal/Modal";
import ModalInput from "../Modal/ModalInput";
import FileInput from "../Input/FileInput";
import Button from "../Input/Button";
import Agenda from "./Agenda";

import ImportIcon from "../../assets/images/Import.svg";
import TrashIcon from "../../assets/images/trash-1.svg";
import EditIcon from "../../assets/images/edit-1.svg";

import conferenceServices from "../../services/conference.services";
import { getTime } from "../../utils/date";
import dayjs from "dayjs";
import { TimePicker } from "antd";

function Agendas(props) {
  const [deletePopup, setDeletePopup] = useState({ open: false, topic: null });
  const [updatePopup, setUpdatePopup] = useState({ open: false, topic: null });

  const [agendasData, setAgendasData] = useState(props.agendas);

  const [count, setCount] = useState(0);

  const groupAgendas = (agendas) => {
    const groupedAgendas = {};
    const getDate = (date) => {
      return new Date(`${date}/${new Date().getFullYear()}`);
    };
    agendas.forEach((agenda) => {
      const date = getDate(agenda.date);
      const topic = {
        _id: agenda._id,
        type: agenda.type,
        topic: agenda.topic,
        location: agenda.location || "",
        description: agenda.description,
        link: agenda.link,
        time: agenda.time,
        startDate: date.toDateString(),
      };

      if (groupedAgendas[date.getTime()]) {
        //check type exist or not
        const typeIndex = groupedAgendas[date.getTime()].types.findIndex(
          (type) => agenda.type === type.type
        );
        if (typeIndex < 0) {
          groupedAgendas[date.getTime()].types.push({
            type: agenda.type,
            topics: [topic],
          });
        } else {
          groupedAgendas[date.getTime()].types[typeIndex].topics.push(topic);
        }
      } else {
        //insert new agenda
        groupedAgendas[date.getTime()] = {
          startDate: date.toDateString(),
          types: [
            {
              type: agenda.type,
              topics: [topic],
            },
          ],
        };
      }
    });

    return Object.keys(groupedAgendas)
      .sort()
      .map((date) => groupedAgendas[date]);
  };

  function handleInput(e) {
    updatePopup.topic[e.target.name] = e.target.value;
    setUpdatePopup({ open: true, topic: { ...updatePopup.topic } });
  }

  function removeAgenda(agendaId) {
    conferenceServices
      .removeAgenda(props.conferenceId, agendaId)
      .then(() => {
        const agendas = agendasData.filter(
          (ag) => `${ag._id}` !== `${agendaId}`
        );
        props.setAgendas([...agendas]);
        alert("Deleted");
        setDeletePopup({ open: false });
      })
      .catch((err) => alert(err.message))
      .finally(() => {});
  }

  function updateAgenda() {
    const data = {
      type: updatePopup.topic.type,
      topic: updatePopup.topic.topic,
      location: updatePopup.topic.location,
      description: updatePopup.topic.description,
      time: `${updatePopup.topic.start} - ${updatePopup.topic.end}`,
      link: updatePopup.topic.link,
    };
    conferenceServices
      .updateAgenda(props.conferenceId, updatePopup.topic._id, data)
      .then(() => {
        let ad = [...agendasData];

        ad.map((v) => {
          if (v._id == updatePopup.topic._id) {
            v = {
              ...v,
              type: data.type,
              topic: data.topic,
              description: data.description,
              location: data.location,
              time: `${updatePopup.topic.start} - ${updatePopup.topic.end}`,
            };
          }
        });

        // props.setAgendas([...ad]);

        // setAgendasData(ad);
        // setCount(count + 1);
        alert("Updated");
        window.location.reload();
        setUpdatePopup({ open: false });
      })
      .catch((err) => alert(err.message))
      .finally(() => {});
  }

  function importAgendas(event) {
    conferenceServices
      .importAgendas(event.target.files[0], props.conferenceId)
      .then((res) => {
        props.setAgendas([...agendasData, ...res.data.agendas]);
      })
      .catch((err) => alert(err.message));
  }

  const handleGetTimeData = (time) => {
    if (time) {
      let now = new Date(time);
      let hours = now.getHours();
      let minutes = now.getMinutes();
      const amOrPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let timeData = `${hours}:${minutes} ${amOrPm}`;
      return {
        timeData: timeData,
      };
    }
  };

  const handleChangeDatePickerValue = (time, name) => {
    const data = handleGetTimeData(time);
    updateDateTime(data, name);
  };

  const updateDateTime = (data, name) => {
    switch (name) {
      case "startTime":
        updatePopup.topic["start"] = data.timeData;
        break;
      case "endTime":
        updatePopup.topic["end"] = data.timeData;
        break;
      default:
    }
    setUpdatePopup({ open: true, topic: { ...updatePopup.topic } });
  };

  useEffect(() => {
    if (updatePopup.topic) {
      if (!updatePopup.topic.start) {
        const start = updatePopup.topic.time.split("-")[0].trim();
        const end = updatePopup.topic.time.split("-")[1].trim();
        updatePopup.topic["start"] = start;
        updatePopup.topic["end"] = end;
        setUpdatePopup({ open: true, topic: { ...updatePopup.topic } });
      }
    }
  }, [updatePopup.topic]);

  useEffect(() => {
    setAgendasData(props.agendas);
  }, [props.agendas]);

  useEffect(() => {
    // console.log("new", agendasData);
  }, [agendasData]);

  return (
    <>
      <ContentHeader>
        <div className="head-block">
          <h4>Agendas</h4>
          <FileInput
            icon={ImportIcon}
            onChange={importAgendas}
            name="Import"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </div>
      </ContentHeader>
      <br />
      {agendasData.length ? (
        <Grid>
          {groupAgendas(agendasData).map((agenda, index) => (
            <Agenda
              {...agenda}
              deleteTopic={(topic) => setDeletePopup({ open: true, topic })}
              updateTopic={(topic) => setUpdatePopup({ open: true, topic })}
              key={index}
            />
          ))}
        </Grid>
      ) : (
        <PlaceHolder>Import Agendas</PlaceHolder>
      )}

      {deletePopup.open && (
        <Modal
          closeModal={() => setDeletePopup({ open: false })}
          icon={TrashIcon}
          heading="Delete"
        >
          <p>{`Are you sure you want to delete agenda ?`}</p>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ margin: "0", color: "#f1f1f1 !important" }}
              btnType="modalBtn"
              name="Cancel"
              onClick={() => setDeletePopup({ open: false })}
            />
            <Button
              style={{ margin: "0", marginLeft: "10px" }}
              btnType="modalBtn"
              name="Delete"
              onClick={() => removeAgenda(deletePopup.topic?._id)}
            />
          </div>
        </Modal>
      )}

      {updatePopup.open && (
        <Modal
          closeModal={() => setUpdatePopup({ open: false })}
          icon={EditIcon}
          heading={`Edit ${updatePopup?.topic?.startDate || ""} Agenda`}
        >
          <ModalForm>
            <ModalInput
              name="type"
              label={"Type"}
              value={updatePopup?.topic?.type || ""}
              placeholder={"Agenda Type"}
              onChange={handleInput}
            />
            <ModalInput
              name="topic"
              label={"Topic"}
              value={updatePopup?.topic?.topic || ""}
              placeholder={"Agenda Topic"}
              onChange={handleInput}
            />
            <ModalInput
              name="location"
              label={"Location"}
              value={updatePopup?.topic?.location || ""}
              placeholder={"Location"}
              onChange={handleInput}
            />

            <ModalInput
              name="link"
              label={"Link"}
              value={updatePopup?.topic?.link || ""}
              placeholder={"Link"}
              onChange={handleInput}
            />

            <ModalInput
              ck={true}
              textArea={true}
              name="description"
              label={"Description"}
              value={updatePopup?.topic?.description || ""}
              placeholder={"Description"}
              onChange={(value) => {
                updatePopup.topic["description"] = value;
                setUpdatePopup({ open: true, topic: { ...updatePopup.topic } });
              }}
            />

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "48.5% 48.5%",
                gridGap: "3%",
              }}
            >
              {/* <ModalInput
                type="time"
                name="startTime"
                label={"Start Time"}
                // value={updatePopup.topic?.start || ""}
                onChange={handleTimeInput}
              /> */}

              <div style={{ marginTop: "20px" }}>
                <label>Start Time</label>
                <TimePicker
                  name="startTime"
                  defaultValue={dayjs(
                    updatePopup.topic
                      ? updatePopup.topic.time.split("-")[0].trim()
                      : "",
                    "hh:mm A"
                  )}
                  use12Hours
                  format="hh:mm A"
                  className="custom_time_picker"
                  onChange={(e) => {
                    if (e) {
                      handleChangeDatePickerValue(e["$d"], "startTime");
                    }
                  }}
                />
              </div>

              <div style={{ marginLeft: "5px", marginTop: "20px" }}>
                <label>End Time</label>
                <TimePicker
                  name="endTime"
                  defaultValue={dayjs(
                    updatePopup.topic
                      ? updatePopup.topic.time.split("-")[1].trim()
                      : "",
                    "hh:mm A"
                  )}
                  use12Hours
                  format="hh:mm A"
                  className="custom_time_picker"
                  onChange={(e) => {
                    if (e) {
                      handleChangeDatePickerValue(e["$d"], "endTime");
                    }
                  }}
                />
              </div>

              {/* <ModalInput
                type="time"
                name="endTime"
                label={"End Time"}
                // value={updatePopup.topic?.end || ""}
                onChange={handleTimeInput}
              /> */}
            </div>
            <Button
              btnType={`${false ? "disabled" : ""} modalBtn`}
              name="EDIT"
              onClick={() => updateAgenda()}
            />
          </ModalForm>
        </Modal>
      )}
    </>
  );
}

export default Agendas;
