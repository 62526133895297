function FileInput(props) {
  return (
    <div className={`form-button file-input`}>
      <div className="label">
        <label>{props.name}</label>
        {props.icon && <img src={props.icon} alt={props.name} />}
      </div>
      <input type="file" accept={props.accept} onChange={props.onChange} />
    </div>
  );
}

export default FileInput;
