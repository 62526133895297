import { AuthAPIs } from "../apis";
import { get, patch, post } from "../utils/request";

async function authenticate(credentials) {
  const response = await post({
    url: AuthAPIs.login,
    data: credentials,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function validate() {
  const response = await get({
    url: AuthAPIs.validateToken,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function update(data) {
  const response = await patch({
    url: AuthAPIs.update,
    data,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function updatePassword(data) {
  const response = await patch({
    url: AuthAPIs.updatePassword,
    data,
  });
  if (response.status !== "success") throw response;
  return response;
}


const services = {
  authenticate,
  validate,
  update,
  updatePassword
};

export default services;
