import { ConferenceCard } from "./Conference.Style";
import CircleArrow from "../../assets/images/circle-arrow.svg";
import ConferenceImg from "../../assets/images/conference.svg";
import SpeakerIcon from "../../assets/images/speaker.svg";
import LocationIcon from "../../assets/images/location.svg";

import { useNavigate } from "react-router-dom";

function Conference(props) {
  const navigate = useNavigate();

  return (
    <ConferenceCard imgUrl={ConferenceImg}>
      <div
        onClick={() => navigate(`/conferences/${props._id}`)}
        className="c-card"
      >
        <h3>{props.name}</h3>
        <ul className="content">
          <li>
            <img alt="conference" src={SpeakerIcon} />
            <span>{props.hostname}</span>
          </li>
          <li>
            <img alt="location" src={LocationIcon} />
            <span>{props.address}</span>
          </li>
        </ul>
        <img className="circle" src={CircleArrow} alt="circle-arrow" />
        <div className="v-card"></div>
      </div>
    </ConferenceCard>
  );
}

export default Conference;
