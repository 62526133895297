import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  width: 100%;
  background: #f8f8f8;
`;

export const TableBody = styled.tbody`
  width: 100%;
`;

export const Tr = styled.tr`
  width: 100%;
  border-bottom: ${(props) => `${props.borderWidth || "1px"} solid #eff1f3`};
  background: transparent;
`;

export const Td = styled.td`
  font-family: GilroyMed;
  font-size: 13px;
  font-weight: 500;
  text-align: ${(props) => props.textAlign || "left"};
  color: ${(props) => props.color || "#a0a0a0"};
  padding: ${(props) => props.padding || "14px 15px"};
  background: transparent;
`;
