import "./Input.css";

function Input(props) {
  return (
    <div className="form-input">
      <img src={props.src} alt={props.label} />
      <div className="form-input-field">
        <label>{props.label}</label>
        <input
          type={props.type || "text"}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
}

export default Input;
