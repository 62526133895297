import { useRef, useEffect, useState } from "react";

import {
  Container,
  Body,
  Content,
  ContentHeader,
} from "../components/Container.Style";
import { ConferenceWrapper } from "../components/Conferences/Conference.Style";
import Conference from "../components/Conferences/Conference";
import Button from "../components/Input/Button";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Search from "../components/Input/Search";

import conferenceServices from "../services/conference.services";

function Conferences() {
  const data = useRef({
    title: "Board Conference",
    hostname: "Abdullah",
    address: "Ghar ke saamne ajana theek",
  });
  const [conferences, setConferences] = useState([]);

  useEffect(() => {
    conferenceServices
      .getConferences()
      .then((res) => setConferences(res.data.conferences))
      .catch((err) => console.log(err.message));
  }, []);

  return (
    <Container>
      <Header name={"Abdullah"} />
      <Body>
        <Sidebar />
        <Content>
          <ContentHeader>
            <div className="head-block">
              <h3>Conference</h3>
            </div>
            <div className="head-block right">
              <Search />
              <Button
                onClick={() => {}}
                btnType={`${false ? "disabled" : ""} conferenceBtn`}
                name="Create Conference"
              />
            </div>
          </ContentHeader>
          <ConferenceWrapper>
            {conferences.map((conference, index) => {
              return <Conference {...conference} key={index} />;
            })}
          </ConferenceWrapper>
        </Content>
      </Body>
    </Container>
  );
}

export default Conferences;
