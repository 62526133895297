import styled from "styled-components";

export const Container = styled.div`
  display: ${(props) => props.display || "grid"};
  grid-template-rows: 12% 88%;
  position: relative;
  width: 97%;
  height: 97%;
  margin: auto;
  padding: 0;
  overflow: hidden;
  border-radius: 20px;
  background: #ffffff;
`;

export const Body = styled.div`
  width: 100%;
  height: calc(100% - 20px);
  margin-top: 20px;
  padding: 0;
  display: flex;
  background: #ffffff;
`;

export const Content = styled.div`
  flex: 1;
  padding: 1% 2%;
  overflow-y: scroll;
  background: #ffffff;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    background: #f7ad00;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #f7ad00;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  justify-content: space-between;

  .head-block {
    display: flex;
    margin: auto 0;
  }
  .head-block > h2 {
    font-family: Gilroy;
    font-size: 46px;
    font-weight: 600;
    color: #ffc727;
    margin: 0;
  }
  .head-block > h3 {
    font-size: 2rem;
    color: #616161;
    font-weight: 600;
    margin: 0;
  }

  .head-block > h4 {
    font-size: 1.75rem;
    font-weight: 500;
    margin: auto 0;
    color: #616161;
    white-space: nowrap;
  }

  .head-block > h5 {
    font-size: 1.25rem;
    font-weight: 500;
    margin: auto 0;
    color: #000000;
    font-family:Poppins;
    white-space: nowrap;
  }

  .right {
    width: ${(props) => props.width || "50%"};
    justify-content: flex-end;
  }
`;

export const ContentBody = styled.div`
  width: 100%;
`;

export const PlaceHolder = styled.div`
  width: 100%;
  padding: 10% 0;
  text-align: center;
  color: #616161;
  font-size: 1.3rem;
  font-family: Gilroy;
  border-radius: 15px;
  background: rgb(241, 241, 241);
  color: rgb(170, 170, 170);
`;

export const ImageContainer = styled.div`
  width: ${(props) => props.width || "100%"};
  height: fit-content;
  padding: 0;
  margin: 0 10px 0 0;
  border-radius: 5px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    index: 2;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: ${(props) => props.height || "100%"};
  margin-top: ${(props) => props.marginTop || "10px"};
  margin-right: ${(props) => props.marginRight || "0px"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  margin-left: ${(props) => props.marginLeft || "0px"};
  img {
    width: ${(props) => props.iconWidth || "15px !important"};
    height: ${(props) => props.iconHeight || "15px"};
    margin-left: 9px;
    cursor: pointer;
  }
`;

export const SettingFormContainer = styled.div`
  width:100%;
  max-width:60%;
  h3 {
    font-family:Gilroy;
    font-weight: 500;
    margin:20px 0;
    color: #616161;
    white-space: nowrap;
  }

`