import LineChart from "./LineChart";
import { CountCardWrapper } from "./Analytics.Style";

const graphData = (props) => {
  const data = [];
  for (let day = 1; day <= 30; day++) {
    data.push({ day, users: Math.round(Math.random() * 5) });
  }
  return data;
};

function CountCard(props) {
  return (
    <CountCardWrapper color={props.color}>
      <div className="head">
        <h2>{props.label}</h2>
        <h2>{props.count}</h2>
      </div>
      <LineChart
        chartData={{
          labels: props.data.map(props.setLabel),
          datasets: [
            {
              data: props.data.map((d) => d.count),
              fill: false,
              borderColor: props.color,
              tension: 0.25,
            },
          ],
        }}
        id={props.id}
      />
    </CountCardWrapper>
  );
}

export default CountCard;
