import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth";

import { HeaderWrapper } from "./Header.style";

import Logo from "../../assets/images/main-logo.png";
import Placeholder from "../../assets/images/ntwicon.png";

function Header() {
  const navigate = useNavigate();
  const { data } = useAuth();

  return (
    <HeaderWrapper>
      <img src={Logo} alt="networking tag" />

      <div className="profile" onClick={() => navigate("/settings")}>
        <div className="profile-content">
          <p>
            Hello, <b>{(data?.user?.name || "").split(" ")[0]}</b>
          </p>
          <span style={{ textTransform: "capitalize" }}>
            {data?.user?.role || ""}
          </span>
        </div>
        <img alt="placeholder" src={Placeholder} />
      </div>
    </HeaderWrapper>
  );
}

export default Header;
