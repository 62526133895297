import { useState, useEffect } from "react";
import {
  Container,
  Body,
  Content,
  ContentHeader,
} from "../components/Container.Style";
import {
  CountCardsContainer,
  MainGraphContainer,
} from "../components/Analytics/Analytics.Style";

import Button from "../components/Input/Button";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import CountCard from "../components/Analytics/CountCard";
import MainGraph from "../components/Analytics/MainGraph";

import analyticServices from "../services/analytics.services";

import { fillData } from "../utils/tool";
import { months } from "../utils/date";
import Select from "../components/Input/Select";

function Dashboard(props) {
  const [filter, setFilter] = useState("daily");
  const [tab, setTab] = useState("users");
  const [data, setData] = useState({
    daily: {
      users: [],
      scans: [],
      logins: [],
      timeSpends: [],
    },
    monthly: {
      users: [],
      scans: [],
      logins: [],
    },
  });

  const setLabel = (data) => {
    if (filter === "monthly") {
      return `${months[data.month]} ${data.year}`;
    } else {
      return `${data.day} ${months[data.month]}`;
    }
  };

  const getTotalCounts = (data) => {
    var counts = 0;
    data.map((data) => (counts += data.count));
    return counts;
  };

  const totalTimeInHour = (data) => {
    const counts = getTotalCounts(data);
    return `${(counts / (60 * 60 * 1000)).toPrecision(2)}`;
  };

  const mapTimeSpends = (data) => {
    return {
      ...data,
      count: (data.count / (60 * 60 * 1000)).toPrecision(2),
    };
  };

  useEffect(() => {
    (async () => {
      await analyticServices
        .fetchAttendeesReport(`filter=monthly`)
        .then((res) => {
          //fill empty data and map label
          data.monthly.users = fillData(res.data, "monthly");
        })
        .catch((err) => {
          console.log(err.message);
        });

      await analyticServices
        .fetchAttendeesReport(`filter=daily`)
        .then((res) => {
          //fill empty data and map label
          data.daily.users = fillData(res.data, "daily");
        })
        .catch((err) => {
          console.log(err.message);
        });

      await analyticServices
        .fetchScansReport(`filter=monthly`)
        .then((res) => {
          //fill empty data and map label
          data.monthly.scans = fillData(res.data, "monthly");
        })
        .catch((err) => {
          console.log(err.message);
        });

      await analyticServices
        .fetchScansReport(`filter=daily`)
        .then((res) => {
          //fill empty data and map label
          data.daily.scans = fillData(res.data, "daily");
        })
        .catch((err) => {
          console.log(err.message);
        });

      await analyticServices
        .fetchLoginsReport(`filter=monthly`)
        .then((res) => {
          //fill empty data and map label
          data.monthly.logins = fillData(res.data, "monthly");
        })
        .catch((err) => {
          console.log(err.message);
        });

      await analyticServices
        .fetchLoginsReport(`filter=daily`)
        .then((res) => {
          //fill empty data and map label
          data.daily.logins = fillData(res.data, "daily");
        })
        .catch((err) => {
          console.log(err.message);
        });

      await analyticServices
        .fetchTimeSpendReport(`filter=daily`)
        .then((res) => {
          //fill empty data and map label
          data.daily.timeSpends = fillData(res.data, "daily");
        })
        .catch((err) => {
          console.log(err.message);
        });

      setData({ ...data });
    })();
  }, [filter]);

  return (
    <Container>
      <Header name={"Abdullah"} />
      <Body>
        <Sidebar />
        <Content>
          <CountCardsContainer>
            <CountCard
              id="1"
              label={"Users added"}
              setLabel={setLabel}
              data={data.daily.users}
              count={getTotalCounts(data.daily.users)}
              color="#f7ad00"
            />
            <CountCard
              id="2"
              label={"Total Login"}
              setLabel={setLabel}
              data={data.daily.logins}
              count={getTotalCounts(data.daily.logins)}
              color="#DC3CCC"
            />
            <CountCard
              id="3"
              label={"QR Scans"}
              setLabel={setLabel}
              data={data.daily.scans}
              count={getTotalCounts(data.daily.scans)}
              color="#2B98D6"
            />
            <CountCard
              id="4"
              setLabel={setLabel}
              data={data.daily.timeSpends.map(mapTimeSpends)}
              label={"Time Spent"}
              count={totalTimeInHour(data.daily.timeSpends)}
              color="#5F5F5F"
            />
          </CountCardsContainer>
          <MainGraphContainer>
            <ContentHeader>
              <div className="head-block">
                <h5>Graph Analytics</h5>
              </div>
              <div className="head-block right">
                <Select
                  options={["Monthly", "Daily"].map((name) => {
                    return {
                      name,
                      value: name.toLocaleLowerCase(),
                    };
                  })}
                  selectedValue={filter === "daily" ? "Daily" : "Monthly"}
                  selectValue={(filter) => {
                    setFilter(filter.value);
                  }}
                />
              </div>
            </ContentHeader>
            <div
              style={{
                display: "grid",
                gridGap: "10px",
                gridTemplateColumns: "150px 150px 150px",
              }}
            >
              <Button
                onClick={() => setTab("users")}
                btnType={`graphBtn ${
                  tab === "users" ? "" : "graphBtn-offline"
                }`}
                name="Users"
              />
              <Button
                onClick={() => setTab("logins")}
                btnType={`graphBtn ${
                  tab === "logins" ? "" : "graphBtn-offline"
                }`}
                name="Logins"
              />
              <Button
                onClick={() => setTab("scans")}
                btnType={`graphBtn ${
                  tab === "scans" ? "" : "graphBtn-offline"
                }`}
                name="Scans"
              />
            </div>
            {tab === "users" && (
              <MainGraph
                chartData={{
                  labels: data[filter].users.map(setLabel),
                  datasets: [
                    {
                      data: data[filter || "daily"].users.map((d) => d.count),
                      borderColor: props.color,
                      tension: 0.2,
                      backgroundColor: "rgba(255, 171, 45, 0.11)", // Background color
                      fill: "origin", // Fill from the origin to the line
                    },
                  ],
                }}
                id={"shadowPlugin"}
              />
            )}
            {tab === "scans" && (
              <MainGraph
                chartData={{
                  labels: data[filter].scans.map(setLabel),
                  datasets: [
                    {
                      data: data[filter || "daily"].scans.map((d) => d.count),
                      borderColor: props.color,
                      tension: 0.4,
                      backgroundColor: "rgba(255, 171, 45, 0.11)", // Background color
                      fill: "origin", // Fill from the origin to the line
                    },
                  ],
                }}
                id={"shadowPlugin"}
              />
            )}
            {tab === "logins" && (
              <MainGraph
                chartData={{
                  labels: data[filter || "daily"].logins.map(setLabel),
                  datasets: [
                    {
                      data: data[filter].logins.map((d) => d.count),
                      borderColor: props.color,
                      tension: 0.4,
                      backgroundColor: "rgba(255, 171, 45, 0.11)", // Background color
                      fill: "origin", // Fill from the origin to the line
                    },
                  ],
                }}
                id={"shadowPlugin"}
              />
            )}
          </MainGraphContainer>
        </Content>
      </Body>
    </Container>
  );
}

export default Dashboard;
