import { useNavigate } from "react-router-dom";

import { NavigationWrapper } from "./Sidebar.style";
import RightArrow from "../../assets/images/right-arrow.png";

function Navigation(props) {
  const navigate = useNavigate();
  const isActive = () => {
    if (props.path) {
      return window.location.pathname === props.path ? "active" : "";
    }
    if (props.click) {
      return "logout";
    }
  };
  return (
    <NavigationWrapper
      onClick={() => (props.click ? props.click() : navigate(props.path))}
      className={isActive()}
    >
      <div className="icon">
        <img
          alt={props.label}
          src={
            ["active", "logout"].includes(isActive())
              ? props.activeIcon
              : props.icon
          }
        />
      </div>
      <span>{props.label}</span>
      <img className="arrow" alt="arrow" src={RightArrow} />
    </NavigationWrapper>
  );
}

export default Navigation;
