import { useState, useRef, useEffect } from "react";
import { AgendaWrapper, TypeWrapper } from "./Conference.Style";
import { ActionWrapper } from "../Container.Style";

import EditIcon from "../../assets/images/edit-1.svg";
import TrashIcon from "../../assets/images/trash-1.svg";
import RightArrow from "../../assets/images/right-arrow.png";

function Type(props) {
  const topicsRef = useRef(null);
  const [active, setActive] = useState(false);
  const getHeight = () => {
    var height = 0;
    [...topicsRef.current.children].forEach(
      (child) => (height += child.clientHeight)
    );
    return height;
  };

  return (
    <TypeWrapper
      onClick={(e) => {
        if (["edit", "remove"].includes(e.target.id)) return;
        setActive(!active);
      }}
      topic-height={active ? getHeight() : 0}
      thmb={active ? "8px" : 0}
    >
      <div className="type-head">
        <h4>{props.type || "Other Type"}</h4>
        <img
          src={RightArrow}
          alt="down"
          className={active ? "img-active" : ""}
        />
      </div>
      <ul ref={topicsRef} className="topics">
        {props.topics.map((topic, index) => {
          return (
            <li key={index}>
              <span className="type">{topic.topic}</span>
              <p>
                <span className="address">
                  {topic.location || "Location Not Available"}
                </span>
                <span className="time">{topic.time}</span>
              </p>
              <ActionWrapper>
                <img
                  id="edit"
                  src={EditIcon}
                  alt="edit"
                  onClick={() => props.updateTopic(topic)}
                />
                <img
                  id="remove"
                  src={TrashIcon}
                  onClick={() => props.deleteTopic(topic)}
                  alt="remove"
                />
              </ActionWrapper>
            </li>
          );
        })}
      </ul>
    </TypeWrapper>
  );
}

function Agenda(props) {
  const allTopics = [];

  props.types.forEach((type) => {
    allTopics.push(...type.topics);
  });

  allTopics.sort((a, b) => {
    const timeA = convertTimeTo24HourFormat(a.time.split("-")[0].trim());
    const timeB = convertTimeTo24HourFormat(b.time.split("-")[0].trim());

    if (timeA < timeB) {
      return 1;
    } else if (timeA > timeB) {
      return -1;
    } else {
      return 0;
    }
  });

  function convertTimeTo24HourFormat(time) {
    try {
      const [hour, minute, period] = time.match(/(\d+):(\d+) (AM|PM)/).slice(1);
      let hour24 = parseInt(hour, 10);

      if (period === "PM" && hour24 !== 12) {
        hour24 += 12;
      } else if (period === "AM" && hour24 === 12) {
        hour24 = 0;
      }

      return hour24 * 60 + parseInt(minute, 10);
    } catch (e) {
      return time;
    }
  }

  let data = {
    type: " ",
    topics: allTopics.reverse(),
  };

  return (
    <AgendaWrapper>
      <h4 className="day">{props.startDate}</h4>
      <div className="types">
        {[data].map((type, index) => (
          <Type
            key={index}
            deleteTopic={props.deleteTopic}
            updateTopic={props.updateTopic}
            {...type}
          />
        ))}
      </div>
    </AgendaWrapper>
  );
}

export default Agenda;
