import { useState, useContext, createContext, useEffect } from "react";

import authServices from "./services/auth.services";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState({ loading: true });

  useEffect(() => {
    authServices
      .validate()
      .then((res) => {
        setData({ loading: false, user: res.data.user });
      })
      .catch(() => {
        if (window.location.pathname !== "/") window.location.href = "/";
      });
  }, []);

  useEffect(() => {
    if (data.loading) return;
    //if user already logged redirect him/her to dashboad
    if (window.location.pathname === "/") window.location.href = "/dashboard";
  }, [data.loading]);

  return (
    <>
      <AuthContext.Provider value={{ data, setData }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthProvider;
