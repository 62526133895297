import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Body,
  Content,
  ContentHeader,
  PlaceHolder,
  ActionWrapper,
} from "../components/Container.Style";
import {
  Table,
  TableBody,
  TableHeader,
  Td,
  Tr,
} from "../components/Table.Style";

import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Button from "../components/Input/Button";
import FileInput from "../components/Input/FileInput";
import Search from "../components/Input/Search";
import Modal from "../components/Modal/Modal";
import { ModalForm } from "../components/Modal/Modal.Style";
import ModalInput from "../components/Modal/ModalInput";
import ConferenceInfo from "../components/Conferences/ConferenceInfo";
import Agendas from "../components/Conferences/Agendas";
import Sponsorships from "../components/Conferences/Sponsorships";
import Exhibits from "../components/Conferences/Exhibits";

import ImportIcon from "../assets/images/Import.svg";
import ExportIcon from "../assets/images/export.svg";
import TrashIcon from "../assets/images/trash-1.svg";
import EditIcon from "../assets/images/edit-1.svg";

import attendeeServices from "../services/attendee.services";
import conferenceServices from "../services/conference.services";
import exhibitServices from "../services/exhibit.services";

function Conference() {
  const params = useParams();

  const [conference, setConference] = useState({ sponsorships: [] });
  const [attendees, setAttendees] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [exhibits, setExhibits] = useState([]);

  const [search, setSearch] = useState("");
  const [image, setImage] = useState({
    file: null,
    dataURL: null,
    progress: false,
  });
  const [progress, setProgress] = useState({
    inProgress: false,
    message: "",
  });
  const [deletePopup, setDeletePopup] = useState({
    open: false,
    attendee: null,
  });
  const [updatePopup, setUpdatePopup] = useState({
    open: false,
    attendee: null,
  });

  function handleInput(e) {
    updatePopup.attendee[e.target.name] = e.target.value;
    setUpdatePopup({ open: true, attendee: { ...updatePopup.attendee } });
  }

  function removeAttendee() {
    if (progress.inProgress) return;
    setProgress({ inProgress: true, message: "Please wait removing attendee" });
    attendeeServices
      .removeAttendee(deletePopup.attendee?._id)
      .then(() => {
        const atends = attendees.filter(
          (s) => `${s._id}` !== `${deletePopup.attendee._id}`
        );
        setAttendees([...atends]);
        setDeletePopup({ open: false, attendee: null });
      })
      .catch((err) => alert(err.message))
      .finally(() => setProgress({ inProgress: false, message: "" }));
  }

  function updateAttendee() {
    if (progress.inProgress) return;
    setProgress({ inProgress: true, message: "Please wait removing attendee" });
    attendeeServices
      .updateAttendee(updatePopup.attendee?._id, {
        name: updatePopup.attendee.name,
        email: updatePopup.attendee.email,
        speaker_profile_link: updatePopup.attendee.speaker_profile_link,
        team_leader: updatePopup.attendee.team_leader,
        sponsor: updatePopup.attendee.sponsor,
      })
      .then(() => {
        const attendeeIndex = attendees.findIndex(
          (attendee) => `${attendee._id}` === `${updatePopup.attendee?._id}`
        );
        attendees[attendeeIndex] = updatePopup.attendee;
        setUpdatePopup({ open: false, attendee: null });
      })
      .catch((err) => alert(err.message))
      .finally(() => setProgress({ inProgress: false, message: "" }));
  }

  function exportAttendees() {
    if (progress.inProgress) return;
    setProgress({
      inProgress: true,
      message: "Please wait, exporting attendees!",
    });
    attendeeServices
      .exportAttendees(params.id)
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => {
        setProgress({
          inProgress: true,
          message: "Please wait, exporting attendees!",
        });
      });
  }

  function importAttendees(event) {
    attendeeServices
      .importAttendees(event.target.files[0], params.id)
      .then((res) => {
        setAttendees([...attendees, ...res.data.attendees]);
      })
      .catch((err) => alert(err.message));
  }

  function importAttendeesTeam(event) {
    attendeeServices
      .importAttendeesTeam(event.target.files[0], params.id)
      .then((res) => {
        //update attendees team
        for (let team of Object.keys(res.teams)) {
          const users = res.teams[team];
          users.forEach((user) => {
            const userIndex = attendees.findIndex(
              (attend) => attend.email === user
            );
            if (userIndex < 0) return;
            attendees[userIndex].team = team;
          });
        }
        setAttendees([...attendees]);
      })
      .catch((err) => alert(err.message));
  }

  async function uploadImage(event) {
    image.file = event.target.files[0];
    image.progress = true;
    setImage({ ...image });
  }

  useEffect(() => {
    //FETCH ATTENDEES
    attendeeServices
      .getAttendees(`conferenceId=${params.id}`)
      .then((response) => {
        setAttendees(response.data.attendees);
      })
      .catch((err) => alert(err.message));

    //FETCH AGENDAS
    conferenceServices
      .fetchAgendas(params.id)
      .then((res) => {
        setAgendas(res.data.agendas);
      })
      .catch((err) => alert(err.message));

    //Fetch Exhibits
    exhibitServices
      .fetchExhibits(params.id)
      .then((res) => setExhibits(res.data.exhibits))
      .catch((err) => console.log(err.message));
  }, [params.id]);

  useEffect(() => {
    attendeeServices
      .getAttendees(
        search
          ? `name[regex]=${search}&name[options]=i`
          : `conferenceId=${params.id}`
      )
      .then((response) => {
        setAttendees(response.data.attendees);
      })
      .catch((err) => alert(err.message));
  }, [search]);

  useEffect(() => {
    if (!image.progress) return;
    conferenceServices
      .updateConferenceImage([{ field: "map", content: image.file }], params.id)
      .then((res) => {
        setConference({ ...conference, ...res.data.images });
      })
      .catch((err) => console.log(err.message))
      .finally(() => {
        setImage({ file: null, dataURL: null, progress: false });
      });
  }, [image]);

  return (
    <Container>
      <Header name={"Abdullah"} />
      <Body>
        <Sidebar />
        <Content>
          <ConferenceInfo
            conferenceId={params.id}
            conference={conference}
            setConference={setConference}
          />
          <ContentHeader>
            <div className="head-block">
              <h4>Attendees List</h4>
              <FileInput
                icon={ImportIcon}
                onChange={importAttendees}
                name="Import"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <FileInput
                icon={ImportIcon}
                onChange={importAttendeesTeam}
                name="Import Team"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <Button
                icon={ExportIcon}
                onClick={exportAttendees}
                btnType={`${false ? "disabled" : ""} exportBtn`}
                name="Export"
              />
            </div>
            <div className="head-block right">
              <Search
                onChange={(event) => setSearch(event.target.value)}
                search={search}
              />
            </div>
          </ContentHeader>
          <br />
          {attendees.length ? (
            <Table>
              <TableHeader>
                <Tr borderWidth={"0px"} background={"transparent"}>
                  <Td>S.No.</Td>
                  <Td>Name</Td>
                  <Td>Email</Td>
                  <Td>Company</Td>
                  <Td>Team</Td>
                  <Td>Registered</Td>
                  {/*<Td>Mail Status</Td>*/}
                  <Td></Td>
                </Tr>
              </TableHeader>
              <TableBody>
                {attendees.map((attendee, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td color="#000000">{attendee.name}</Td>
                      <Td>{attendee.email.replace(/\+.*@/, "@")}</Td>
                      <Td>{attendee.company || "-"}</Td>
                      <Td color={attendee.team ? "#000000" : "#a0a0a0"}>
                        {attendee.team || "Not Assigned"}
                      </Td>
                      <Td color={attendee.registered ? "green" : "#a0a0a0"}>
                        {attendee.registered ? "Registered" : "Pending"}
                      </Td>
                      {/*<Td color={attendee.mailSend ? "green" : "#a0a0a0"}>
                      {attendee.mailSend ? "Sent" : "Pending"}
                </Td>*/}
                      <Td>
                        <ActionWrapper>
                          <img
                            id="edit"
                            src={EditIcon}
                            alt="edit"
                            onClick={() =>
                              setUpdatePopup({ open: true, attendee })
                            }
                          />
                          <img
                            id="remove"
                            src={TrashIcon}
                            onClick={() =>
                              setDeletePopup({ open: true, attendee })
                            }
                            alt="remove"
                          />
                        </ActionWrapper>
                      </Td>
                    </Tr>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <PlaceHolder>Import Attendees</PlaceHolder>
          )}
          <br />
          <Agendas
            conferenceId={params.id}
            agendas={agendas}
            setAgendas={setAgendas}
          />
          <br />
          <Sponsorships
            resources={true}
            conference={conference}
            setConference={setConference}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Sponsorships conference={conference} setConference={setConference} />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/* <Exhibits
            conference={conference}
            exhibits={exhibits}
            setExhibits={setExhibits}
            image={image}
            setImage={setImage}
            updateImage={uploadImage}
          /> */}
        </Content>
      </Body>
      {deletePopup.open && (
        <Modal
          closeModal={() => setDeletePopup({ open: false })}
          icon={TrashIcon}
          heading="Delete"
        >
          <p>{`Are you sure you want to delete ${
            deletePopup?.attendee?.name
          } (${deletePopup.attendee?.email || ""}) ?`}</p>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ margin: "0", color: "#f1f1f1 !important" }}
              btnType="modalBtn"
              name="Cancel"
              onClick={() => setDeletePopup({ open: false })}
            />
            <Button
              style={{ margin: "0", marginLeft: "10px" }}
              btnType="modalBtn"
              name="Delete"
              onClick={removeAttendee}
            />
          </div>
        </Modal>
      )}
      {updatePopup.open && (
        <Modal
          closeModal={() => setUpdatePopup({ open: false })}
          icon={EditIcon}
          heading={`Edit Attendee`}
        >
          <ModalForm>
            <ModalInput
              name="name"
              label={"Name"}
              value={updatePopup?.attendee?.name || ""}
              placeholder={""}
              onChange={handleInput}
            />
            <ModalInput
              name="email"
              label={"Email"}
              value={updatePopup?.attendee?.email || ""}
              placeholder={""}
              onChange={handleInput}
            />

            <ModalInput
              name="speaker_profile_link"
              label={"Speaker Profile Link"}
              value={updatePopup?.attendee?.speaker_profile_link || ""}
              placeholder={""}
              onChange={handleInput}
            />

            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div style={{ padding: "15px 0px 0 0" }}>
                <div style={{ padding: "0px 0px 5px 0px" }}>Sponsor</div>
                <ToggleButton
                  onChange={(value) => {
                    updatePopup.attendee["sponsor"] = value;
                    setUpdatePopup({
                      open: true,
                      attendee: { ...updatePopup.attendee },
                    });
                  }}
                  value={updatePopup?.attendee?.sponsor}
                />
              </div>

              <div style={{ padding: "15px 0px 0 0px" }}>
                <div style={{ padding: "0px 0px 5px 0px" }}>Team Leader</div>
                <ToggleButton
                  onChange={(value) => {
                    updatePopup.attendee["team_leader"] = value;
                    setUpdatePopup({
                      open: true,
                      attendee: { ...updatePopup.attendee },
                    });
                  }}
                  value={updatePopup?.attendee?.team_leader}
                />
              </div>
            </div>

            <Button
              btnType={`${false ? "disabled" : ""} modalBtn`}
              name="EDIT"
              onClick={updateAttendee}
            />
          </ModalForm>
        </Modal>
      )}
    </Container>
  );
}

const ToggleButton = ({ value, onChange }) => {
  const [buttonState, setButtonState] = useState(false);

  useEffect(() => {
    setButtonState(value);
  }, []);

  return (
    <label className="sfasfS_switch">
      <input
        type="checkbox"
        checked={buttonState}
        onClick={() => {
          onChange(!buttonState);
          setButtonState(!buttonState);
        }}
      />
      <span className="sfasfS_slider round"></span>
    </label>
  );
};

export default Conference;
