import FileSaver from "file-saver";

import { AttendeeAPIs } from "../apis";
import { readExcelFile } from "../utils/excelReader";
import { get, patch, remove, post } from "../utils/request";

async function getAttendees(query) {
  const response = await get({
    url: `${AttendeeAPIs.fetchAttendees}?${query}`,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function importAttendees(file, conferenceId) {
  //read data from excel and map it according to our needs
  const jsonData = await readExcelFile(file, {
    Company: "company",
    "First Name": "firstName",
    "Last Name": "lastName",
    "Job Title": "jobTitle",
    "Job Level": "jobLevel",
    "Role Function": "roleFunction",
    "Business Type": "businessType",
    "E-mail": "email",
    "Prior WCF attendance": "priorWcfAttendance",
    "Interaction style": "interactionStyle",
    Team: "team",
    "Mobile Phone": "mobile",
    State: "state",
  });

  let attendeesData = [];
  jsonData.every((value, index) => {
    let emptyCheck = true;

    Object.keys(value).map((k, i) => {
      if (value[k] != "") {
        emptyCheck = false;
      }
    });
    if (emptyCheck) {
      return false;
    } else {
      attendeesData.push(value);
    }

    return true;
  });

  console.log("attendeesData", attendeesData);

  //send import request
  const response = await post({
    url: `${AttendeeAPIs.importAttendees}?conferenceId=${conferenceId}`,
    data: { attendees: attendeesData },
  });

  if (response.status !== "success") throw response;

  if (response.data.errors) {
    const error = response.data.errorLogs
      .map((err) => `Error on row ${err.row}, ${err.error}`)
      .join("\n");
    const blob = new Blob([error], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, `ErrorLogs_${Date.now()}.txt`);
  }

  return response;
}

async function importAttendeesTeam(file, conferenceId) {
  //read data from excel and map content
  const jsonData = await readExcelFile(file, undefined, (rows) => {
    const teamIndex = rows[0].indexOf("Team");
    const emailIndex = rows[0].indexOf("Email");
    if (teamIndex < 0 || emailIndex < 0) return {};

    const teams = {};
    for (let row = 1; row < rows.length; row++) {
      const team = rows[row][teamIndex];
      const email = rows[row][emailIndex];
      if (!team || !email) continue;
      if (teams[team]) teams[team].push(email);
      else teams[team] = [email];
    }
    return teams;
  });

  const response = await patch({
    url: `${AttendeeAPIs.importAttendeesTeam}?conferenceId=${conferenceId}`,
    data: { teams: jsonData },
  });

  if (response.status !== "success") throw response;

  if (response.data.errors) {
    const error = response.data.errorLogs
      .map((err) => `Error on row ${err.row}, ${err.error}`)
      .join("\n");
    const blob = new Blob([error], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, `ErrorLogs_${Date.now()}.txt`);
  }

  return { teams: jsonData };
}

async function exportAttendees(conferenceId) {
  const response = await get({
    url: `${AttendeeAPIs.exportAttendees}?conferenceId=${conferenceId}`,
    options: {
      responseType: "blob",
    },
    customResHandler: (res) => {
      if (res.data?.status && res.data.status !== "success") {
        return res.data;
      } else {
        return {
          status: "success",
          filedata: res.data,
          filename: res.headers["content-disposition"].split("filename=")[1],
        };
      }
    },
  });

  if (response.status !== "success") throw response;

  FileSaver.saveAs(response.filedata, response.filename);

  return { status: "success" };
}

async function updateAttendee(attendeeId, data) {
  const response = await patch({
    url: AttendeeAPIs.updateAttendee(attendeeId),
    data,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function removeAttendee(attendeeId) {
  const response = await remove({
    url: AttendeeAPIs.removeAttendee(attendeeId),
  });
  if (response.status !== "success") throw response;
  return response;
}

const services = {
  getAttendees,
  importAttendees,
  exportAttendees,
  importAttendeesTeam,
  updateAttendee,
  removeAttendee,
};

export default services;
