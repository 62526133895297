import { ExhibitAPIs } from "../apis";

import { get, post, patch, remove } from "../utils/request";
import { readExcelFile } from "../utils/excelReader";

async function fetchExhibits(conferenceId) {
  const response = await get({
    url: `${ExhibitAPIs.fetchExhibits}?conferenceId=${conferenceId}`,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function importExhibits(file, conferenceId) {
  //read data from excel and map content
  const jsonData = await readExcelFile(file, {
    Name: "name",
    Booth: "booth",
  });
  const response = await post({
    url: `${ExhibitAPIs.importExhibits}?conferenceId=${conferenceId}`,
    data: { exhibits: jsonData },
  });
  if (response.status !== "success") throw response;
  return response;
}

async function updateExhibit(exhibitId, data) {
  const response = await patch({
    url: ExhibitAPIs.updateExhibit(exhibitId),
    data,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function removeExhibit(exhibitId) {
  const response = await remove({
    url: ExhibitAPIs.removeExhibit(exhibitId),
  });
  if (response.status !== "success") throw response;
  return response;
}

const services = {
  fetchExhibits,
  importExhibits,
  updateExhibit,
  removeExhibit,
};

export default services;
