import { SponsorshipWrapper } from "./Conference.Style";
import { ActionWrapper } from "../Container.Style";

import TrashIcon from "../../assets/images/trash-1.svg";
import EditIcon from "../../assets/images/edit-1.svg";

function Sponsorship(props) {
  return (
    <SponsorshipWrapper>
      <img src={props.sponsorship.logo} alt={props.sponsorship.name} />
      <p>{props.sponsorship.name}</p>
      <ActionWrapper
        height={"fit-content"}
        marginTop={"auto"}
        marginBottom={"auto"}
        marginLeft={"auto"}
        marginRight={0}
      >
        <img
          id="edit"
          src={EditIcon}
          alt="edit"
          onClick={() => props.updateSponsor(props.sponsorship)}
        />
        <img
          id="remove"
          src={TrashIcon}
          onClick={() => props.deleteSponsor(props.sponsorship)}
          alt="remove"
        />
      </ActionWrapper>
    </SponsorshipWrapper>
  );
}

export default Sponsorship;
