export const fillData = ({ report, from, to }, filter) => {
  // Convert the data array to a map for easier manipulation
  const getKey = ({ year, month, day }) => {
    return filter === "daily"
      ? `${year}-${month}-${day}`
      : `${year}-${month}`;
  };

  const getValue = (date) => {
    const value = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      count: 0,
    };
    if (filter === "daily") value["day"] = date.getDate();
    return value;
  };

  const dataMap = new Map(report.map((data) => [getKey(data), data]));

  // Get the start and end dates from the first and last items in the data array
  const startDate = new Date(from);
  const endDate = new Date(to);

  // Fill the gaps with zero count and add the missing dates to the data map
  for (
    let date = new Date(startDate);
    date < endDate;
    date.setDate(date.getDate() + 1)
  ) {
    const value = getValue(date);
    const key = getKey(value);
    if (!dataMap.has(key)) {
      dataMap.set(key, value);
    }
  }

  const getDate = (data) => {
    if (filter === "daily") {
      return new Date(data.year, data.month - 1, data.day);
    } else {
      return new Date(data.year, data.month - 1);
    }
  };

  return Array.from(dataMap.values()).sort((a, b) => getDate(a) - getDate(b));
};
