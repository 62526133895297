import * as XLSX from "xlsx";

function defaultMapJsonContent(rows, headMapper, isValidRow) {
  if (!headMapper) throw { message: "Please provide columns" };
  const data = [];
  const header = rows[0];
  for (let row = 1; row < rows.length; row++) {
    const rowData = {};
    header.forEach((colHead, index) => {
      const key = headMapper[colHead];
      if (key) rowData[key] = rows[row][index] || "";
    });
    if (isValidRow) {
      if (isValidRow(rowData)) {
        data.push(rowData);
      }
    } else {
      data.push(rowData);
    }
  }
  return data;
}

export function readExcelFile(file, cols, contentMapper, isValidRow) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw:false });
      const jsonData = contentMapper
        ? contentMapper(data)
        : defaultMapJsonContent(data, cols, isValidRow);
      resolve(jsonData);
    };
    reader.onerror = (err) => reject(err);
  });
}
