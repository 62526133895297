import styled from "styled-components";

export const SearchWrapper = styled.div`
  width: ${(props) => props.width || "100%"};
  max-width:320px;
  background: #f1f1f1;
  display: flex;
  img {
    width: 15px;
    height: 15px;
    margin: auto 10px auto 15px;
    background: transparent;
  }
  input {
    width: 100%;
    padding: 15px 5px;
    background: transparent;
    border: transparent;
    color: #aaaaaa;
    :placeholder {
      color: #aaaaaa;
    }
    outline-style:none;
  }
`;
