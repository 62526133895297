import styled from "styled-components";

export const HeaderWrapper = styled.header`
  width: 95%;
  padding: 1.5% 2.5%;
  display: flex;
  justify-content:space-between;
  box-shadow: 18px 4px 35px rgba(0, 0, 0, 0.02);
  background:#ffffff;
  img {
    width:180px;
    min-width:150px
    height: fit-content;
    margin: auto 0;
    background: #ffffff;
  }
  .profile {
    display: flex;
    cursor:pointer;
    background: #ffffff;
  }
  .profile > .profile-content {
    display:flex;
    flex-direction:column;
    margin-right:20px;
    background: #ffffff;
  }
  .profile > .profile-content > p {
    font-size:16px;
    margin: 0;
    background: #ffffff;
  }
  .profile > .profile-content > span {
    margin:auto 0 auto auto;
    color:grey;
    font-size:12px;
    background: #ffffff;
  }
  .profile > .profile-content > p > b {
    font-size:1rem;
    font-weight:bold;
    background: #ffffff;
  }
  .profile > img {
    width: 50px;
    height: 50px;
  }
`;
