const ORIGIN = "https://api.networkingtag.com";
//const ORIGIN = "http://127.0.0.1:5003";

export const AuthAPIs = {
  login: `${ORIGIN}/api/v1/auth/login?role=admin`,
  validateToken: `${ORIGIN}/api/v1/auth/validate`,
  update: `${ORIGIN}/api/v1/auth/admin-profile`,
  updatePassword: `${ORIGIN}/api/v1/auth/update-password`,
};

export const ConferenceAPIs = {
  fetchConferences: `${ORIGIN}/api/v1/conferences`,
  fetchConference: (id) => `${ORIGIN}/api/v1/conferences/${id}`,
  updateConference: (id) => `${ORIGIN}/api/v1/conferences/${id}`,
  updateImageConference: (id) => `${ORIGIN}/api/v1/conferences/${id}/image`,
  importSponsorships: (id) =>
    `${ORIGIN}/api/v1/conferences/${id}/sponsorships/import`,
  updateSponsorship: (conferenceId, sponsorId, resources) =>
    `${ORIGIN}/api/v1/conferences/${conferenceId}/sponsorships/${sponsorId}?resources=${resources}`,
  removeSponsorship: (conferenceId, sponsorId, resources) =>
    `${ORIGIN}/api/v1/conferences/${conferenceId}/sponsorships/${sponsorId}?resources=${resources}`,
  importAgendas: (id) => `${ORIGIN}/api/v1/conferences/${id}/agendas/import`,
  fetchAgendas: (id) => `${ORIGIN}/api/v1/conferences/${id}/agendas`,
  addAgenda: (conferenceId) =>
    `${ORIGIN}/api/v1/conferences/${conferenceId}/agendas`,
  updateAgenda: (conferenceId, agendaId) =>
    `${ORIGIN}/api/v1/conferences/${conferenceId}/agendas/${agendaId}`,
  removeAgenda: (conferenceId, agendaId) =>
    `${ORIGIN}/api/v1/conferences/${conferenceId}/agendas/${agendaId}`,
};

export const AttendeeAPIs = {
  fetchAttendees: `${ORIGIN}/api/v1/attendees`,
  importAttendees: `${ORIGIN}/api/v1/attendees/import`,
  exportAttendees: `${ORIGIN}/api/v1/attendees/export`,
  importAttendeesTeam: `${ORIGIN}/api/v1/attendees/import-teams`,
  updateAttendee: (id) => `${ORIGIN}/api/v1/attendees/${id}`,
  removeAttendee: (id) => `${ORIGIN}/api/v1/attendees/${id}`,
};

export const ExhibitAPIs = {
  fetchExhibits: `${ORIGIN}/api/v1/exhibits`,
  importExhibits: `${ORIGIN}/api/v1/exhibits/import`,
  updateExhibit: (exhibitId) => `${ORIGIN}/api/v1/exhibits/${exhibitId}`,
  removeExhibit: (exhibitId) => `${ORIGIN}/api/v1/exhibits/${exhibitId}`,
};

export const AnalyticAPIs = {
  attendeesCount: `${ORIGIN}/api/v1/analytics/attendees`,
  scansCount: `${ORIGIN}/api/v1/analytics/scans`,
  loginsCount: `${ORIGIN}/api/v1/analytics/logins`,
  timeSpendCount: `${ORIGIN}/api/v1/analytics/timespends`,
};
