import { SideBarWrapper } from "./Sidebar.style";
import Navigation from "./Navigation";

import Analytics from "../../assets/images/analytics.svg";
import AnalyticsActive from "../../assets/images/analytics-active.svg";
import Dashboard from "../../assets/images/dashboard.svg";
import DashboardActive from "../../assets/images/dashboard-active.svg";
import User from "../../assets/images/user.svg";
import UserActive from "../../assets/images/user-active.svg";
import Setting from "../../assets/images/settings.svg";
import SettingActive from "../../assets/images/settings-active.svg";
import Microphone from "../../assets/images/microphone.svg";
import MicrophoneActive from "../../assets/images/microphone-active.svg";
import SignOut from "../../assets/images/sign-out.svg";

function Sidebar() {
  return (
    <SideBarWrapper>
      <Navigation
        label="Dashboard"
        path="/dashboard"
        icon={Dashboard}
        activeIcon={DashboardActive}
      />
      <Navigation
        label="User"
        path="/users"
        icon={User}
        activeIcon={UserActive}
      />
      <Navigation
        label="Conferences"
        path="/conferences"
        icon={Microphone}
        activeIcon={MicrophoneActive}
      />
      <Navigation
        label="Analytics"
        path="/analytics"
        icon={Analytics}
        activeIcon={AnalyticsActive}
      />
      <Navigation
        label="Settings"
        path="/settings"
        icon={Setting}
        activeIcon={SettingActive}
      />
      <Navigation
        label="Logout"
        click={() => {
          window.localStorage.removeItem("token");
          window.location.href = "/";
        }}
        icon={Setting}
        activeIcon={SignOut}
      />
    </SideBarWrapper>
  );
}

export default Sidebar;
