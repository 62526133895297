import Chart from "chart.js/auto";
import { GraphWrapper } from "./Analytics.Style";
import { Line } from "react-chartjs-2";

function LineChart(props) {
  return (
    <GraphWrapper>
      <Line
        key={props.id}
        data={props.chartData}
        options={{
          layout: {
            autoPadding: false,
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          elements: {
            point: {
              pointStyle: false,
            },
            line: {
              borderWidth: 1.4,
            },
          },
          scales: {
            x: {
              border: {
                width: 0,
                display: false,
              },
              grid: {
                display: false,
              },
              ticks: {
                display: false,
                padding: 0,
              },
            },
            y: {
              display: false,
              border: {
                width: 0,
                display: false,
              },
              grid: {
                display: false,
              },
              ticks: {
                display: false,
                padding: 0,
              },
            },
          },
        }}
      />
    </GraphWrapper>
  );
}

export default LineChart;
