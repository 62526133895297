import { ModalContainer, ModalBody, ModalHead, ModalForm } from "./Modal.Style";
import CloseIcon from "../../assets/images/close.svg";

function Modal(props) {
  return (
    <ModalContainer>
      <ModalBody width={props.width}>
        <ModalHead>
          <div className="left">
            {props.icon && <img alt="close" src={props.icon} />}
            <span>{props.heading}</span>
          </div>
          <img alt="close" src={CloseIcon} onClick={props.closeModal} />
        </ModalHead>
        {props.children}
      </ModalBody>
    </ModalContainer>
  );
}

export default Modal;
