import { AnalyticAPIs } from "../apis";
import { get } from "../utils/request";

async function fetchAttendeesReport(query) {
  const response = await get({
    url: `${AnalyticAPIs.attendeesCount}?${query}`,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function fetchScansReport(query) {
  const response = await get({
    url: `${AnalyticAPIs.scansCount}?${query}`,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function fetchLoginsReport(query) {
  const response = await get({
    url: `${AnalyticAPIs.loginsCount}?${query}`,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function fetchTimeSpendReport(query) {
  const response = await get({
    url: `${AnalyticAPIs.timeSpendCount}?${query}`,
  });
  if (response.status !== "success") throw response;
  return response;
}

const services = {
  fetchAttendeesReport,
  fetchScansReport,
  fetchLoginsReport,
  fetchTimeSpendReport
};

export default services;
