import styled from "styled-components";

export const CountCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  grid-gap: 2.67%;
`;

export const CountCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  box-shadow: 0px 8.45621px 16.2077px rgba(62, 73, 84, 0.04);
  .head {
    display: flex;
    justify-content: space-between;
    width: 85%;
    padding: 5% 7.5% 0 7.5%;
  }
  .head > h2 {
    font-family: Poppins;
    font-weight: 600;
    margin: 0;
    color: #0f0f0f;
  }
  .head > h2:nth-child(2) {
    color: ${(props) => props.color || "#f7ad00"};
  }
`;

export const GraphWrapper = styled.div`
  display: flex;
  height: ${props => props.height || "60px" };
  width: 100%;
  padding: 0;
  margin: 0 auto;
  justify-content: center;
`;

export const MainGraphContainer = styled.div`
  width: calc(100% - 40px);
  padding: 20px;
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  border-radius: 1px;
`;
