import { useRef, useEffect, useState } from "react";
import { ModalInputWrapper } from "./Modal.Style";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function ModalInput(props) {
  const [editorData, setEditorData] = useState("");

  const editorConfiguration = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "|",
      "undo",
      "redo",
    ],
  };

  return (
    <ModalInputWrapper>
      {props.textArea ? (
        <>
          <label>{props.label}</label>
          {props.ck ? (
            <>
              <div style={{ paddingTop: "5px" }}>
                <CKEditor
                  config={editorConfiguration}
                  editor={ClassicEditor}
                  data={editorData.length > 0 ? editorData : props.value}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    props.onChange(data);
                    setEditorData(data);
                  }}
                />
              </div>
            </>
          ) : (
            <textarea
              className="modal-input"
              name={props.name}
              onChange={props.onChange}
              type={props.type}
              value={props.value}
              placeholder={props.placeholder}
              readOnly={props.readOnly}
              rows="5"
              // cols="30"
            ></textarea>
          )}
        </>
      ) : (
        <>
          <label>{props.label}</label>
          <input
            className="modal-input"
            name={props.name}
            onChange={props.onChange}
            type={props.type}
            value={props.value}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
          />
        </>
      )}
    </ModalInputWrapper>
  );
}

export default ModalInput;
