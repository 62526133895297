import styled from "styled-components";

export const SideBarWrapper = styled.div`
  width: 280px;
  height:100%;
  background: #ffffff;
  .active {
    border-left: 14px solid #f7ad00;
    background: #fffffa;
  }
  .active > span {
    color: #f7ad00;
  }
  .logout > span {
    color: #FF6262;
  }
`;
export const NavigationWrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 80%;
  padding: 10%;
  border-left: 15px solid #ffffff;
  background: #ffffff;
  .icon {
    display: flex;
    width: 25px;
    height: 25px;
    margin-right: 30px;
    height: fit-content;
    margin: auto 30px auto 0;
    background: #ffffff;
  }
  .icon > img {
    margin: auto;
    background: #ffffff;
  }
  .arrow {
    margin: auto 0 auto auto;
    background: #ffffff;
  }
  span {
    margin: auto 0;
    color: #969ba0;
    font-family: Poppins;
    background: #ffffff;
  }
`;
